<template>
  <section>
    <div class="content-header">
      <h2>A brief social-belonging intervention improves academic and health outcomes of minority students</h2>
    </div>
    <div class="content-wrapper">
      <p><a href="https://pubmed.ncbi.nlm.nih.gov/21415354/" target="_blank">This study</a> explored interventions aimed at strengthening first-year college students’ sense of belonging in a randomized controlled trial. Academic and health-related consequences were reported over three years.</p>
      <p>The intervention aimed to reduce students’ psychological perceptions of threat on campus by framing social adversity as common and short-lived.</p>
      <p>It used subtle attitude-change strategies to lead participants to generate the intervention message on their own. </p>
      <p>The intervention was expected to be particularly beneficial to African-American students, a stereotyped and socially marginalized group in academics, and less so to European-American students.</p>
      <p>Consistent with these expectations, over the three-year observation period, the intervention raised African-American students’ GPA relative to multiple control groups and halved the minority achievement gap.</p>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
